import React, { FC, ReactElement, useEffect, useState } from 'react'
import clsx from 'clsx'
import moment, { Moment } from 'moment-timezone'
import { memoize } from 'lodash'
import Datetime from 'react-datetime'

export interface UseDaySelectorProps {
    date?: Moment
    onDateChange?: (date: Moment, isNewDateToday: boolean) => void
}

interface DateSelectPickerProps {}

export interface UseDaySelectorOutput {
    setDate: (date: Moment) => void
    isToday: boolean
    date: Moment | null
    DateSelectPicker: (props: DateSelectPickerProps) => ReactElement
}

export const useDaySelector: (
    props?: UseDaySelectorProps
) => UseDaySelectorOutput = (props) => {
    const { onDateChange } = props ?? {}
    const [date, setDate] = useState<Moment | null>(
        props?.date ? moment(props?.date) : null
    )
    const [isChanging, setIsChanging] = useState<boolean>(false)

    useEffect(() => {
        if (!moment(props?.date).isSame(moment(date), 'day')) {
            setDate(props?.date ? moment(props?.date) : null)
        }
    }, [props?.date])

    useEffect(() => {
        setIsChanging(false)
    }, [props?.date])

    const isToday = !date || moment(date).isSame(moment(), 'day')

    const handleTodayClick = () => {
        setIsChanging(true)
        const newDate = moment().startOf('day')
        if (typeof onDateChange === 'function')
            onDateChange(
                newDate,
                !newDate || moment(newDate).isSame(moment(), 'day')
            )
    }

    const handleDayClick = (days) => {
        setIsChanging(true)
        const newDate = date
            ? moment(date).add(days, 'days')
            : moment().add(days, 'days')
        setDate(newDate)
        if (typeof onDateChange === 'function')
            onDateChange(
                newDate,
                !newDate || moment(newDate).isSame(moment(), 'day')
            )
    }

    return {
        setDate,
        isToday,
        date,
        DateSelectPicker: memoize(({}) => {
            const buttonClassNames = clsx(
                'btn btn-xs btn-outline text-white bg-teal-600 hover:bg-teal-700 dark:bg-docpace-dark rounded-sm'
            )
            return (
                <div className={clsx('flex relative mt-0.5 items-center')}>
                    {!isToday && (
                        <button
                            disabled={isChanging}
                            children="Go To Today"
                            onClick={handleTodayClick}
                            className={clsx(buttonClassNames)}
                        />
                    )}
                    {isToday && (
                        <div
                            className={
                                'py-1 px-2 pr-3 flex justify-center font-semibold'
                            }
                        >
                            Today
                        </div>
                    )}
                    <div className="mr-1">
                        <Datetime
                            dateFormat={'ddd MMM Do, YYYY'}
                            timeFormat={false}
                            value={moment(date ?? moment())}
                            onChange={(date) => {
                                setIsChanging(true)
                                onDateChange?.(
                                    moment(date),
                                    moment(props?.date).isSame(
                                        moment(date),
                                        'day'
                                    )
                                )
                            }}
                            inputProps={{
                                style: { top: -1 },
                                className:
                                    'input text-center input-bordered relative input-xs h-6 text-black dark:text-gray-50 w-48 rounded-sm pl-2 h-8',
                            }}
                        />
                    </div>
                    <button
                        disabled={isChanging}
                        children="Back"
                        onClick={() => handleDayClick(-1)}
                        className={buttonClassNames}
                    />
                    <button
                        disabled={isChanging}
                        children="Forward"
                        onClick={() => handleDayClick(1)}
                        className={buttonClassNames}
                    />
                </div>
            )
        }),
    }
}

export default useDaySelector
