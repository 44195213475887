import moment, { Moment } from 'moment-timezone'
import { first, orderBy, compact, last, sortBy } from 'lodash'

export const areDatesSameDay: (
    dayA: string | Moment | null | undefined,
    dayB: string | Moment | null | undefined
) => boolean = (dayA, dayB) => {
    return (
        !!dayA &&
        !!dayB &&
        moment(dayA).format('YYYY-MM-DD') === moment(dayB).format('YYYY-MM-DD')
    )
}

export const sortTimestampsFromEarliestToLatest = (timestamps: (string|null|undefined)[]): string[] => {
    return sortBy(compact(timestamps), (a) => (new Date(a).getTime()))
}

export const getEarliestTimestampInSet = (timestamps: (string|null|undefined)[]): string | undefined => {
    return first(sortTimestampsFromEarliestToLatest(compact(timestamps)))
}

export const getLatestTimestampInSet = (timestamps: (string|null|undefined)[]): string | undefined => {
    return last(sortTimestampsFromEarliestToLatest(compact(timestamps)))
}

export function isWithinSeconds(timestamp, seconds) {
    const nowDate = new Date()
    const now = nowDate.getTime() + (nowDate.getTimezoneOffset() * 60000)

    const targetDate = new Date(timestamp)  
    const targetTime = targetDate.getTime() + (targetDate.getTimezoneOffset() * 60000)

    const differenceInSeconds = Math.abs((now - targetTime) / 1000);
    return differenceInSeconds <= seconds;
}

export function minutesIntoDayIntoFormattedTime(minutes:number): (string|null){
    if(!minutes) return null
    const amPm = minutes >= 12*60 ? 'PM' : 'AM'
    const hours = (minutes - minutes % 60)/60
    const displayHours = hours > 12 ? hours - 12 : hours
    const _minutes = minutes - (hours * 60)
    return `${displayHours}${_minutes > 0 ? ':'.concat(_minutes.toString().padStart(2, '0')) : ''}${amPm}`
}

export function delayPromise(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}