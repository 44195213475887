import { useQuery, QueryHookOptions } from '@apollo/client'
import { useAtom } from 'jotai'

import { ManagerPracticeBaseQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerPracticeBaseQueryResultFragment, ManagerPracticeBaseQueryParams } from '@docpace/manager-graphql'
import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'
import moment, { Moment } from 'moment'

export function useManagerPracticeBaseQuery(opts?: QueryHookOptions<ManagerPracticeBaseQueryResultFragment, Partial<ManagerPracticeBaseQueryParams>>){
    const queryResult
    = useQuery<ManagerPracticeBaseQueryResultFragment>(ManagerPracticeBaseQueryDocument, opts)
    return queryResult
}

interface UseContextManagerPracticeBaseQueryProps {
    dayStart?: Moment
    dayEnd?: Moment
    skip?: boolean
}

export function useContextManagerPracticeBaseQuery(props?: UseContextManagerPracticeBaseQueryProps){
    const {
        dayStart = moment().startOf('day'),
        dayEnd = moment().startOf('day'),
        skip
    } = props ?? {}
    const [ practiceId ] = useAtom(ctxPracticeIdAtom)
    const managerBaseQueryResult = useManagerPracticeBaseQuery({
        variables: {
            practiceId,
            dayStart: dayStart.format('YYYY-MM-DD'),
            dayEnd: dayEnd.format('YYYY-MM-DD'),
        },
        skip: !practiceId || skip,
    })
    const { data } = managerBaseQueryResult

    return {
        practice: data?.practice,
        departments: data?.departments?.nodes,
        providers: data?.providers?.nodes,
        ...managerBaseQueryResult
    }
}
