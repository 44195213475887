import { ApolloClient, split, HttpLink, ApolloLink } from '@apollo/client'
import { cache } from '@docpace/apps/manager-app/cache'

import {
    makeApolloErrorLink,
    makeApolloPersistedOperationsLink,
    makeApolloHttpLink,
    makeApolloWebsocketLink,
    apolloAuthLink,
    makeApolloLink,
    makeApolloRefreshTokenLink,
} from '@docpace/shared-react-apollo-client'
import hashes from '@docpace/manager-graphql/dist/client-persisted.json'
import { makeGraphqlApiHttpUrl, makeGraphqlApiWsUrl } from '@docpace/shared-ts-utils/clientUtils'

export const makeServerHostname = () => {
    return (
        // envHost ??
        ((typeof window !== 'undefined' && window.location.hostname === 'localhost') ? 'localhost:3012' : null) ??
        (typeof window !== 'undefined' ? window.location.hostname : '')
    )
}  
export const serverHostname = makeServerHostname()
const httpLink = makeApolloHttpLink(makeGraphqlApiHttpUrl(serverHostname))
const wsLink = makeApolloWebsocketLink(makeGraphqlApiWsUrl(serverHostname))

const link = makeApolloLink(httpLink, wsLink, apolloAuthLink)

export const apolloClient = new ApolloClient({
    link: ApolloLink.from([
        makeApolloRefreshTokenLink({ serverHostname }),
        makeApolloErrorLink(),
        makeApolloPersistedOperationsLink(hashes),
        link,
    ]),
    cache,
})
